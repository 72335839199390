import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import _ from "lodash";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import { GlobeAltIcon } from "@heroicons/react/outline";

import { prevPageUrl, nextPageUrl } from "../utils/common";
import { Layout } from "../components";
import { PrimarySection } from "../components/common";
import { MetaData } from "../components/common/meta";
import { TwitterSvg } from "../components/svg";

/**
 * Author page (/authors/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Author = ({ data, location, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const author = data.ghostAuthor;
  const posts = data.allGhostPost.edges;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const baseUrl = `/authors/${author.slug}`;
  const prevPage = prevPageUrl(baseUrl, currentPage);
  const nextPage = nextPageUrl(baseUrl, currentPage);

  return (
    <>
      <MetaData data={data} location={location} type="profile" />
      <Layout title={`Articles by ${author.name}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
          <div>
            {author.profile_image && (
              <figure className="mb-6">
                <img
                  className="inline-block h-16 w-16 rounded-full"
                  src={author.profile_image}
                  alt={author.name}
                />
              </figure>
            )}
            <div className="flex space-x-9 items-center mb-3">
              <h1 className="uppercase text-2xl">{author.name}</h1>
              <div className="flex space-x-3">
                {author.twitter && (
                  <Link
                    to={`https://twitter.com/${author.twitter.replace(
                      /^@/,
                      ``
                    )}/`}
                    target="_blank"
                    className="w-6 h-6 rounded-full bg-black flex items-center justify-center"
                  >
                    <span className="sr-only">
                      Follow {author.name} on Twitter
                    </span>
                    <TwitterSvg className="h-3 w-3 text-white" />
                  </Link>
                )}
                {author.website && (
                  <Link
                    to={author.website}
                    target="_blank"
                    className="w-6 h-6 rounded-full bg-black flex items-center justify-center"
                  >
                    <span className="sr-only">
                      Checkout {author.name}'s website
                    </span>
                    <GlobeAltIcon className="h-3 w-3 text-white" />
                  </Link>
                )}
              </div>
            </div>
            {author.bio && <div className="text-gray-500">{author.bio}</div>}
          </div>
          {_.chunk(posts, 7).map((posts, i) => (
            <PrimarySection key={`posts-${i}`} posts={posts} isHome={false} />
          ))}
          <nav className="px-4 flex items-center justify-between sm:px-0">
            {!isFirst && (
              <div className="-mt-px w-0 flex-1 flex">
                <Link
                  to={prevPage}
                  className="pt-4 pr-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 h-5 w-5"
                    aria-hidden="true"
                  />
                  Previous
                </Link>
              </div>
            )}
            {!isLast && (
              <div className="-mt-px w-0 flex-1 flex justify-end">
                <Link
                  to={nextPage}
                  className="pt-4 pl-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  Next
                  <ArrowNarrowRightIcon
                    className="ml-3 h-5 w-5"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            )}
          </nav>
        </div>
      </Layout>
    </>
  );
};

Author.propTypes = {
  data: PropTypes.shape({
    ghostAuthor: PropTypes.object.isRequired,
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Author;

export const pageQuery = graphql`
  query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostAuthor(slug: { eq: $slug }) {
      ...GhostAuthorFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
